<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm" size="small" label-position="right">
          <el-form-item label="管线名称" class="searchItem">
            <el-input
              v-model="pageParams.name"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
         
          <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="search"
                  >
                  </el-cascader>
           
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="search" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" >导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin-top: 10px ;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 390px)"
            :stripe="true"
          >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="pipeName"
            width="180"
            label="管线名称"
            show-overflow-tooltip
          >
          </el-table-column>
       
          <el-table-column
            prop="pressureLevelName"
            label="压力级别"
            show-overflow-tooltip
          >
          </el-table-column>
           <el-table-column
            prop="pipeTypeName"
            label="管材"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column
            prop="pipeLength"
            label="管线长度(m)"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="diameterName"
            label="公称直径"
            show-overflow-tooltip
          >
          </el-table-column>
       
          <el-table-column
            prop="layerName"
            label="所属图层"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="100">
            <template slot-scope="{row}">
              <el-button type="text" @click="getDetail(row)" size="mini">管段详情</el-button>
            </template> 
          </el-table-column>
        </el-table>
      <div class="total">
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins"
import { exportMethod } from '../../common/js/exportExcel'
import { exportStatisticsStandingBook, pageStatisticsStandingBook} from "../../RequestPort/gisbook"
import { commonDict, serverMap } from '../Gis/utils/dict'
import { findDictTree, selectDictListByPid } from '../Gis/apis/commonType'
import { pipeFindById, pipeUpdate } from '../Gis/apis/tool'

import coverlayer from "../Gis/apis/coverlayer"
export default {
  name: 'PipeLineList',
  components: {
  },
  mixins: [pageMixins],
  data() {
    return {
      titelName:'',
      pressureLevelList:serverMap['1006'],
      pipeTypeList:[],
      diameterList:[],
      diameterListTemp:[],
      searchDate:[],
      pageParams: {
        name:'',
        layerId:'',
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [], //  表格数据
      selectList:[],
     
      buildTypeList: [],
      pipeTypeList: [],
      pipeModelList: [],
      diameterList: [],
      diameterData: [],
      gasTypeList: [],
      pipeStatusList: [],
      standardList: [],
      antisepticTypeList: [],
      pipeLengthTotal: null,
      lineArr: [],
      confirmStatusList:[],
      
      
      loadingD:true,
      isEdit:false,
      formOld:{},
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    };
  },
  mounted() {
    this.getCodeNameList() 
  },
  methods: {
    exportFile(){
      if(this.tableData.length <= 0) {
        this.$message.error("查询无数据，无法导出，请更换查询条件后再试")
        return
      }
      exportStatisticsStandingBook(this.pageParams).then(res=>{
        exportMethod(res,'管线台账报表')
      })
    },

   async getCodeNameList(){
      let p = { sysType: 1}
      let res = await coverlayer.getFindTree(p)
      this.optionsList = res.data
    },
   
  
   
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.diameterList = []
      this.searchDate = []
      this.pageParams = {
        name:'',
        layerId:'',
        current: 1,
        size: 100,
      }
      this.loadList(this.pageParams)
    },
    loadList(obj){
      pageStatisticsStandingBook(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },

   
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    getDetail(row){
      this.$router.push({
        path:'pipeLineList?pipeName='+row.pipeName
      })
    },
 
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 215px;
}
.contain{
  height: 690px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
.btn{text-align: right;}
</style>